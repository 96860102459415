.shop_product.shop_product_from_cat_29 {
    /* Change the white to any color ;) */
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active  {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    }

    .breadcrumbs {
        .innerbreadcrumbs {
            .path {
                .bred-2 {
                    display: none;
                }
            }
        }
    }

    .centercol {
        width: 100%;
        #box_productfull {
            .boxhead {
                .name {
                    font-size: 29px;
                    padding: .3em 1.5em;
                    @media screen and (max-width: 767px) {
                        font-size: 24px;
                        text-align: center;
                        padding: .3em 0;
                    }
                }
            }
            .product-main-box {
                .maininfo {
                    .f-grid-6 {
                        &.productimg {
                            .mainimg {
                                background: white;
                                .tags {
                                    display: none;
                                } 
                            }
                            .productDimensions {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                        }
                        &:nth-child(2) {
                            background: white;
                            padding: 50px 50px 70px 50px;
                            @media screen and (max-width: 979px) {
                                padding: 25px 20px 50px 20px;
                            }
                            .availability  {
                                display: none; 
                            }
         
                            .bottomborder {
                                .basket {
                                    .price {
                                        display: none;
                                    }
                                    .form-basket {
                                        position: relative;
                                        .stocks {
                                            .f-row {
                                                .label {
                                                    padding: 0 10px;
                                                    line-height: 35px;
                                                }
                                                .stock-options {
                                                    .inputwrap {
                                                        height: 55px;
                                                        input {
                                                            width: 100%;
                                                            padding: 0 15px; 
                                                            &.error {
                                                                font-size: 14px;
                                                                color: @redColor;
                                                                background: white;
                                                            }
                                                        }
                                                        .error {
                                                            background: @redColor;
                                                            color: white;
                                                            font-size: 12px;
                                                            padding: 0 15px;
                                                            border-radius: 4px;
                                                            line-height: 20px;
                                                        }
                                                    }
                                                    .option_select {
                                                        select {
                                                            padding: 0 15px;
                                                        }
                                                    }
                                                }

                                                &:last-of-type { 
                                                    max-width: 25%;
                                                    position: absolute;
                                                    bottom: -10px; 
                                                    .label { 
                                                        display: none;
                                                    }
                                                    .stock-options {  
                                                        .inputwrap { 
                                                            display: flex;
                                                            align-items: center;
                                                            @media screen and (max-width: 979px) {
                                                                padding-top: 10px;
                                                            }
                                                            .quantity-unit {
                                                                order: 1; 
                                                                margin-left: 7px;
                                                                font-size: 16px;
                                                                font-weight: bold;
                                                                @media screen and (max-width: 470px) {
                                                                    margin-left: 3px;
                                                                    font-size: 14px;
                                                                }  
                                                            }
                                                              
                                                            input {
                                                                max-width: 40px;
                                                                text-align: center;
                                                                font-weight: bold;
                                                                color: black;
                                                                padding: 0;
                                                            } 
                                                        }
                                                    }
                                                }
                                            }
                                        } 
                                        .addtobasket-container {
                                            display: block; 
                                           // justify-content: flex-end;
                                           // flex-direction: column;
                                           // align-items: center;
                                            margin-top: 35px;
                                            #customPrice {
                                                display: flex; 
                                                justify-content: flex-end;
                                                align-items: baseline; 
                                                margin-bottom: 12px; 
                                                width: 100%;
                                                font-size: 14px;
                                                font-weight: bold;
 
                                                @media screen and (max-width: 979px) {
                                                    align-items: center; 
                                                    margin-bottom: 25px; 
                                                }
                                                @media screen and (max-width: 470px) {
                                                    font-size: 13px;
                                                }

                                                .mainPrice, .mainPrice-unit {
                                                    color: @mainColor;
                                                    font-size: 24px;
                                                    margin-left: 3px;
                                                }
                                            }
                                            .quantity_wrap { 
                                                display: none;
                                            }
                                            .button_wrap { 
                                                max-width: 75%; 
                                                margin-left: 25%;

                                                //margin-top: -57px;

                                                .loyalty_points {
                                                    position: absolute;
                                                    right: 0;
                                                    bottom: -39px; 

                                                    font-size: 16px; 
                                                    font-weight: bold;
                                                    color: black; 
                                                    @media screen and (max-width: 470px) {
                                                        font-size: 14px;
                                                    }
                                                }
                                                
                                            }
                                        }
                                    }
                                    .addtofav {
                                        display: none;
                                    }
                                    > div {
                                        &:last-of-type {
                                            display: none;
                                        }
                                    }
                                }
         
                                .productdetails-more-details {
                                    border-top: 0;
                                    border-bottom: 0;
                                    margin-top: 0;
                                    .productdetails-more { 
                                        display: none;
                                    }
                                    .links-q {
                                        li.question {
                                            position: absolute;
                                            right: 250px; 
                                            @media screen and (max-width: 979px) {
                                                right: 200px;
                                            }
                                            @media screen and (max-width: 470px) {
                                                padding-top: 3px;
                                            }  
                                            @media screen and (max-width: 390px) {
                                                right: 175px; 
                                            }  
                                            .question {  
                                                color: black;
                                                font-size: 16px; 
                                                font-weight: bold;
                                                @media screen and (max-width: 470px) {
                                                    font-size: 14px; 
                                                }  
                                                span {
                                                    &:before {
                                                        content: '';
                                                    }
                                                }
                                            }
                                        }
                                        .mailfriend, .comment {
                                            display: none;
                                        }
                                        #calc7 {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                    }

                } 
            } 
        }

        .product-modules {
            background: white;
            padding: 40px 30px;
            #box_description {
                .innerbox {
                    .resetcss {
                        ul {
                            padding-left: 20px;
                            li {
                                font-size: 16px;
                                line-height: 2.25;
                            }
                        }
                    }
                }
            }
            #box_productgallery {
                .boxhead {
                    h3 {
                        margin: 0;
                        line-height: inherit;
                        font-size: 2em;
                        font-weight: 700;
                        display: block;
                        padding: .3em 0;
                    }
                }
            }
            #box_productcomments {
                display: none;
            }
        }
    }
    .leftcol { 
        display: none; 
    }
}