#shop_infopage22 #infopage22 .boxhead {
  text-align: center;
}
#shop_infopage22 #infopage22 .boxhead > span {
  font-size: 36px;
  font-weight: bold;
}
.stepHeader {
  text-align: center;
  margin: 10px 0;
}
.stepHeader > span {
  font-size: 36px;
  font-weight: bold;
}
.productSteps {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 5rem;
}
.productSteps__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  max-width: 600px;
  position: relative;
}
.productSteps__wrapper .step-space {
  width: 200px;
  height: 1px;
  background: black;
}
.productSteps__wrapper .step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.productSteps__wrapper .step__number {
  font-size: 36px;
  font-weight: bold;
  line-height: 1.2;
  border: 1px solid black;
  border-radius: 50%;
  width: 75px;
  height: 75px;
  margin: 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.productSteps__wrapper .step__title {
  font-size: 18px;
  line-height: 1.2;
}
.productSteps__wrapper .step--done .step__number {
  background: #41d2bf;
  border-color: #41d2bf;
  color: white;
}
.productSteps__wrapper .step--active .step__number {
  background: #41d2bf;
  border-color: #41d2bf;
  box-shadow: 0px 3px 27px 0px rgba(1, 1, 1, 0.23);
  color: white;
}
#box_basketlist .productlist tr[data-category="Poduszki szyte na wymiar"] td.quantity {
  opacity: 0;
}
#box_basketlist .productlist tr[data-category="Poduszki szyte na wymiar"] td.price {
  opacity: 0;
}
#box_menu .standard #category_29 {
  display: none;
}
#rwd-menu [data-category-id="29"] {
  display: none;
}
#shop_infopage22 .centercol {
  width: 100%;
}
#shop_infopage22 .leftcol {
  display: none;
}
.customProducts {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
@media screen and (max-width: 767px) {
  .customProducts {
    flex-direction: column;
  }
}
.customProducts .customProduct__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.customProducts .customProduct__image {
  width: 100%;
  max-width: 280px;
  height: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}
.customProducts .customProduct__image .customProduct__thumbnail {
  max-height: 220px;
}
.customProducts .customProduct__wrapper {
  max-width: 280px;
}
.customProducts .customProduct__wrapper .customProduct__header {
  background: transparent;
  min-height: 130px;
}
.customProducts .customProduct__wrapper .customProduct__title {
  font-size: 20px;
  line-height: 1.2;
  text-align: center;
  margin-top: 20px;
}
.shop_product.shop_product_from_cat_29 {
  /* Change the white to any color ;) */
}
.shop_product.shop_product_from_cat_29 input:-webkit-autofill,
.shop_product.shop_product_from_cat_29 input:-webkit-autofill:hover,
.shop_product.shop_product_from_cat_29 input:-webkit-autofill:focus,
.shop_product.shop_product_from_cat_29 input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.shop_product.shop_product_from_cat_29 .breadcrumbs .innerbreadcrumbs .path .bred-2 {
  display: none;
}
.shop_product.shop_product_from_cat_29 .centercol {
  width: 100%;
}
.shop_product.shop_product_from_cat_29 .centercol #box_productfull .boxhead .name {
  font-size: 29px;
  padding: 0.3em 1.5em;
}
@media screen and (max-width: 767px) {
  .shop_product.shop_product_from_cat_29 .centercol #box_productfull .boxhead .name {
    font-size: 24px;
    text-align: center;
    padding: 0.3em 0;
  }
}
.shop_product.shop_product_from_cat_29 .centercol #box_productfull .product-main-box .maininfo .f-grid-6.productimg .mainimg {
  background: white;
}
.shop_product.shop_product_from_cat_29 .centercol #box_productfull .product-main-box .maininfo .f-grid-6.productimg .mainimg .tags {
  display: none;
}
.shop_product.shop_product_from_cat_29 .centercol #box_productfull .product-main-box .maininfo .f-grid-6.productimg .productDimensions {
  display: flex;
  justify-content: center;
  align-items: center;
}
.shop_product.shop_product_from_cat_29 .centercol #box_productfull .product-main-box .maininfo .f-grid-6:nth-child(2) {
  background: white;
  padding: 50px 50px 70px 50px;
}
@media screen and (max-width: 979px) {
  .shop_product.shop_product_from_cat_29 .centercol #box_productfull .product-main-box .maininfo .f-grid-6:nth-child(2) {
    padding: 25px 20px 50px 20px;
  }
}
.shop_product.shop_product_from_cat_29 .centercol #box_productfull .product-main-box .maininfo .f-grid-6:nth-child(2) .availability {
  display: none;
}
.shop_product.shop_product_from_cat_29 .centercol #box_productfull .product-main-box .maininfo .f-grid-6:nth-child(2) .bottomborder .basket .price {
  display: none;
}
.shop_product.shop_product_from_cat_29 .centercol #box_productfull .product-main-box .maininfo .f-grid-6:nth-child(2) .bottomborder .basket .form-basket {
  position: relative;
}
.shop_product.shop_product_from_cat_29 .centercol #box_productfull .product-main-box .maininfo .f-grid-6:nth-child(2) .bottomborder .basket .form-basket .stocks .f-row .label {
  padding: 0 10px;
  line-height: 35px;
}
.shop_product.shop_product_from_cat_29 .centercol #box_productfull .product-main-box .maininfo .f-grid-6:nth-child(2) .bottomborder .basket .form-basket .stocks .f-row .stock-options .inputwrap {
  height: 55px;
}
.shop_product.shop_product_from_cat_29 .centercol #box_productfull .product-main-box .maininfo .f-grid-6:nth-child(2) .bottomborder .basket .form-basket .stocks .f-row .stock-options .inputwrap input {
  width: 100%;
  padding: 0 15px;
}
.shop_product.shop_product_from_cat_29 .centercol #box_productfull .product-main-box .maininfo .f-grid-6:nth-child(2) .bottomborder .basket .form-basket .stocks .f-row .stock-options .inputwrap input.error {
  font-size: 14px;
  color: #ed1c24;
  background: white;
}
.shop_product.shop_product_from_cat_29 .centercol #box_productfull .product-main-box .maininfo .f-grid-6:nth-child(2) .bottomborder .basket .form-basket .stocks .f-row .stock-options .inputwrap .error {
  background: #ed1c24;
  color: white;
  font-size: 12px;
  padding: 0 15px;
  border-radius: 4px;
  line-height: 20px;
}
.shop_product.shop_product_from_cat_29 .centercol #box_productfull .product-main-box .maininfo .f-grid-6:nth-child(2) .bottomborder .basket .form-basket .stocks .f-row .stock-options .option_select select {
  padding: 0 15px;
}
.shop_product.shop_product_from_cat_29 .centercol #box_productfull .product-main-box .maininfo .f-grid-6:nth-child(2) .bottomborder .basket .form-basket .stocks .f-row:last-of-type {
  max-width: 25%;
  position: absolute;
  bottom: -10px;
}
.shop_product.shop_product_from_cat_29 .centercol #box_productfull .product-main-box .maininfo .f-grid-6:nth-child(2) .bottomborder .basket .form-basket .stocks .f-row:last-of-type .label {
  display: none;
}
.shop_product.shop_product_from_cat_29 .centercol #box_productfull .product-main-box .maininfo .f-grid-6:nth-child(2) .bottomborder .basket .form-basket .stocks .f-row:last-of-type .stock-options .inputwrap {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 979px) {
  .shop_product.shop_product_from_cat_29 .centercol #box_productfull .product-main-box .maininfo .f-grid-6:nth-child(2) .bottomborder .basket .form-basket .stocks .f-row:last-of-type .stock-options .inputwrap {
    padding-top: 10px;
  }
}
.shop_product.shop_product_from_cat_29 .centercol #box_productfull .product-main-box .maininfo .f-grid-6:nth-child(2) .bottomborder .basket .form-basket .stocks .f-row:last-of-type .stock-options .inputwrap .quantity-unit {
  order: 1;
  margin-left: 7px;
  font-size: 16px;
  font-weight: bold;
}
@media screen and (max-width: 470px) {
  .shop_product.shop_product_from_cat_29 .centercol #box_productfull .product-main-box .maininfo .f-grid-6:nth-child(2) .bottomborder .basket .form-basket .stocks .f-row:last-of-type .stock-options .inputwrap .quantity-unit {
    margin-left: 3px;
    font-size: 14px;
  }
}
.shop_product.shop_product_from_cat_29 .centercol #box_productfull .product-main-box .maininfo .f-grid-6:nth-child(2) .bottomborder .basket .form-basket .stocks .f-row:last-of-type .stock-options .inputwrap input {
  max-width: 40px;
  text-align: center;
  font-weight: bold;
  color: black;
  padding: 0;
}
.shop_product.shop_product_from_cat_29 .centercol #box_productfull .product-main-box .maininfo .f-grid-6:nth-child(2) .bottomborder .basket .form-basket .addtobasket-container {
  display: block;
  margin-top: 35px;
}
.shop_product.shop_product_from_cat_29 .centercol #box_productfull .product-main-box .maininfo .f-grid-6:nth-child(2) .bottomborder .basket .form-basket .addtobasket-container #customPrice {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  margin-bottom: 12px;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
}
@media screen and (max-width: 979px) {
  .shop_product.shop_product_from_cat_29 .centercol #box_productfull .product-main-box .maininfo .f-grid-6:nth-child(2) .bottomborder .basket .form-basket .addtobasket-container #customPrice {
    align-items: center;
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 470px) {
  .shop_product.shop_product_from_cat_29 .centercol #box_productfull .product-main-box .maininfo .f-grid-6:nth-child(2) .bottomborder .basket .form-basket .addtobasket-container #customPrice {
    font-size: 13px;
  }
}
.shop_product.shop_product_from_cat_29 .centercol #box_productfull .product-main-box .maininfo .f-grid-6:nth-child(2) .bottomborder .basket .form-basket .addtobasket-container #customPrice .mainPrice,
.shop_product.shop_product_from_cat_29 .centercol #box_productfull .product-main-box .maininfo .f-grid-6:nth-child(2) .bottomborder .basket .form-basket .addtobasket-container #customPrice .mainPrice-unit {
  color: #41d2bf;
  font-size: 24px;
  margin-left: 3px;
}
.shop_product.shop_product_from_cat_29 .centercol #box_productfull .product-main-box .maininfo .f-grid-6:nth-child(2) .bottomborder .basket .form-basket .addtobasket-container .quantity_wrap {
  display: none;
}
.shop_product.shop_product_from_cat_29 .centercol #box_productfull .product-main-box .maininfo .f-grid-6:nth-child(2) .bottomborder .basket .form-basket .addtobasket-container .button_wrap {
  max-width: 75%;
  margin-left: 25%;
}
.shop_product.shop_product_from_cat_29 .centercol #box_productfull .product-main-box .maininfo .f-grid-6:nth-child(2) .bottomborder .basket .form-basket .addtobasket-container .button_wrap .loyalty_points {
  position: absolute;
  right: 0;
  bottom: -39px;
  font-size: 16px;
  font-weight: bold;
  color: black;
}
@media screen and (max-width: 470px) {
  .shop_product.shop_product_from_cat_29 .centercol #box_productfull .product-main-box .maininfo .f-grid-6:nth-child(2) .bottomborder .basket .form-basket .addtobasket-container .button_wrap .loyalty_points {
    font-size: 14px;
  }
}
.shop_product.shop_product_from_cat_29 .centercol #box_productfull .product-main-box .maininfo .f-grid-6:nth-child(2) .bottomborder .basket .addtofav {
  display: none;
}
.shop_product.shop_product_from_cat_29 .centercol #box_productfull .product-main-box .maininfo .f-grid-6:nth-child(2) .bottomborder .basket > div:last-of-type {
  display: none;
}
.shop_product.shop_product_from_cat_29 .centercol #box_productfull .product-main-box .maininfo .f-grid-6:nth-child(2) .bottomborder .productdetails-more-details {
  border-top: 0;
  border-bottom: 0;
  margin-top: 0;
}
.shop_product.shop_product_from_cat_29 .centercol #box_productfull .product-main-box .maininfo .f-grid-6:nth-child(2) .bottomborder .productdetails-more-details .productdetails-more {
  display: none;
}
.shop_product.shop_product_from_cat_29 .centercol #box_productfull .product-main-box .maininfo .f-grid-6:nth-child(2) .bottomborder .productdetails-more-details .links-q li.question {
  position: absolute;
  right: 250px;
}
@media screen and (max-width: 979px) {
  .shop_product.shop_product_from_cat_29 .centercol #box_productfull .product-main-box .maininfo .f-grid-6:nth-child(2) .bottomborder .productdetails-more-details .links-q li.question {
    right: 200px;
  }
}
@media screen and (max-width: 470px) {
  .shop_product.shop_product_from_cat_29 .centercol #box_productfull .product-main-box .maininfo .f-grid-6:nth-child(2) .bottomborder .productdetails-more-details .links-q li.question {
    padding-top: 3px;
  }
}
@media screen and (max-width: 390px) {
  .shop_product.shop_product_from_cat_29 .centercol #box_productfull .product-main-box .maininfo .f-grid-6:nth-child(2) .bottomborder .productdetails-more-details .links-q li.question {
    right: 175px;
  }
}
.shop_product.shop_product_from_cat_29 .centercol #box_productfull .product-main-box .maininfo .f-grid-6:nth-child(2) .bottomborder .productdetails-more-details .links-q li.question .question {
  color: black;
  font-size: 16px;
  font-weight: bold;
}
@media screen and (max-width: 470px) {
  .shop_product.shop_product_from_cat_29 .centercol #box_productfull .product-main-box .maininfo .f-grid-6:nth-child(2) .bottomborder .productdetails-more-details .links-q li.question .question {
    font-size: 14px;
  }
}
.shop_product.shop_product_from_cat_29 .centercol #box_productfull .product-main-box .maininfo .f-grid-6:nth-child(2) .bottomborder .productdetails-more-details .links-q li.question .question span:before {
  content: '';
}
.shop_product.shop_product_from_cat_29 .centercol #box_productfull .product-main-box .maininfo .f-grid-6:nth-child(2) .bottomborder .productdetails-more-details .links-q .mailfriend,
.shop_product.shop_product_from_cat_29 .centercol #box_productfull .product-main-box .maininfo .f-grid-6:nth-child(2) .bottomborder .productdetails-more-details .links-q .comment {
  display: none;
}
.shop_product.shop_product_from_cat_29 .centercol #box_productfull .product-main-box .maininfo .f-grid-6:nth-child(2) .bottomborder .productdetails-more-details .links-q #calc7 {
  display: none;
}
.shop_product.shop_product_from_cat_29 .centercol .product-modules {
  background: white;
  padding: 40px 30px;
}
.shop_product.shop_product_from_cat_29 .centercol .product-modules #box_description .innerbox .resetcss ul {
  padding-left: 20px;
}
.shop_product.shop_product_from_cat_29 .centercol .product-modules #box_description .innerbox .resetcss ul li {
  font-size: 16px;
  line-height: 2.25;
}
.shop_product.shop_product_from_cat_29 .centercol .product-modules #box_productgallery .boxhead h3 {
  margin: 0;
  line-height: inherit;
  font-size: 2em;
  font-weight: 700;
  display: block;
  padding: 0.3em 0;
}
.shop_product.shop_product_from_cat_29 .centercol .product-modules #box_productcomments {
  display: none;
}
.shop_product.shop_product_from_cat_29 .leftcol {
  display: none;
}
