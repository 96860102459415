#box_basketlist {
    .productlist {
        tr[data-category="Poduszki szyte na wymiar"] {
            td {
                &.quantity {
                    opacity: 0;
                }
                &.price {
                    opacity: 0;
                }
            }
        }
    }
} 