#box_menu {
    .standard {
        #category_29 {
            display: none;
        }
    }
} 
#rwd-menu {
    [data-category-id="29"] {
        display: none;
    }
}
