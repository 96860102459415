#shop_infopage22 {
    #infopage22 {
        .boxhead {
            text-align: center;
            > span {
                font-size: 36px;
                font-weight: bold;
            }
        }
    }
}

.stepHeader {
    text-align: center;
    margin: 10px 0;
    > span {
        font-size: 36px;
        font-weight: bold;
    }
}
.productSteps {
    display: flex;
    justify-content: center; 
    align-items: center;
    text-align: center;
    margin-bottom: 5rem; 
    &__wrapper {
        display: flex;
        justify-content: space-between;  
        align-items: baseline;

        width: 100%; 
        max-width: 600px;

        position: relative;

        .step-space {
            width: 200px;
            height: 1px;
            background: black;
        }

        .step {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            &__number {
                font-size: 36px;
                font-weight: bold;
                line-height: 1.2;
                border: 1px solid black;
                border-radius: 50%;

                width: 75px;
                height: 75px;
                margin: 15px 0; 

                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
            }

            &__title {
                font-size: 18px;
                line-height: 1.2;
            }

            &--done {
                .step__number {  
                    background: @mainColor; 
                    border-color: @mainColor;
                    color: white; 
                }
            }

            &--active {
                .step__number {  
                    background: @mainColor; 
                    border-color: @mainColor;
                    box-shadow: 0px 3px 27px 0px rgba(1, 1, 1, 0.23);
                    color: white; 
                }
            }
        }
    }
}


  