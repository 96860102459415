.customProducts {
    display: flex;
    align-items: center;
    justify-content: space-around;
    @media screen and (max-width: 767px) {
        flex-direction: column;
    }
    .customProduct {
        &__container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;  
            text-align: center;
        } 
        &__image {
            width: 100%;
            max-width: 280px;
            height: 280px;

            display: flex;
            justify-content: center;
            align-items: center; 

            background: white;

            .customProduct__thumbnail {
                max-height: 220px;
            }
        }

        &__wrapper {
            max-width: 280px;
            .customProduct__header {
                background: transparent;
                min-height: 130px;
            }
            .customProduct__title {
                font-size: 20px;
                line-height: 1.2;
                text-align: center;
                margin-top: 20px;
            }
        }
    }
}